/* prevent Material's toast element from controlling positioning, allowing Toastify to control positioning */
.MuiSnackbar-root {
  top: unset !important;
  left: unset !important;
  right: unset !important;
  position: unset !important;
}

/* eliminate Toastify's toast container visually so that we only see Material's toast element */
.Toastify__toast-container {
  width: unset;
}
.Toastify__toast-body {
  margin: 0;
  padding: 0;
}
.Toastify__toast {
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
  min-height: unset;
  min-width: unset;
  box-shadow: unset;
  overflow: unset;
}
